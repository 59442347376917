import React, { useState } from "react";
import logo from "../assets/logo.svg";
import { Menu, Phone, X } from "lucide-react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="w-full bg-white md:bg-transparent p-4 md:p-8 z-50 flex items-center justify-between md:justify-center fixed md:relative">
      <button
        className="md:hidden text-gray-800 z-50" // Added z-50 to ensure the button is on top
        onClick={toggleMenu}
        aria-label="Toggle Menu"
      >
        {isOpen ? <X className="w-8 h-8" /> : <Menu className="w-8 h-8" />}
      </button>

      {/* Centered text that appears only when the menu is closed on mobile */}
      {!isOpen && (
        <a href="tel:+33631070881" className="md:hidden">
          <button className="font-poppins bg-white text-blue-500 border border-blue-500 px-3 py-1 rounded-full font-medium flex items-center active:scale-90 duration-200">
            Appeler
            <Phone
              color={"#3b82f6"}
              strokeWidth={2.5}
              className="w-4 h-4 ml-2"
            />
          </button>
        </a>
      )}

      <ul
        className={`${
          isOpen ? "block" : "hidden"
        } h-screen justify-between text-3xl md:text-base py-32 md:py-0 md:flex flex flex-col md:flex-row z-40 gap-12 md:gap-0 items-center absolute md:static bg-white md:bg-transparent w-full left-0 top-0 md:top-auto md:w-auto md:h-auto`}
      >
        <li>
          <a
            href="#"
            className="hover:text-blue-500 font-medium md:font-normal px-6 py-3"
            onClick={closeMenu}
          >
            Accueil
          </a>
        </li>
        <li>
          <a
            href="#portfolio"
            className="hover:text-blue-500 font-medium md:font-normal px-6 py-3"
            onClick={closeMenu}
          >
            Portfolio
          </a>
        </li>
        <li>
          <a
            href="#a-propos"
            className="hover:text-blue-500 font-medium md:font-normal px-6 py-3"
            onClick={closeMenu}
          >
            A propos
          </a>
        </li>
        <li className="hidden md:block hover:scale-105 duration-100">
          <a href="#">
            <img src={logo} className="h-12 mx-12 cursor-pointer" alt="" />
          </a>
        </li>
        <li>
          <a
            href="#tarifs"
            className="hover:text-blue-500 font-medium md:font-normal px-6 py-3"
            onClick={closeMenu}
          >
            Tarifs
          </a>
        </li>

        <li>
          <a
            href="#temoignages"
            className="hover:text-blue-500 font-medium md:font-normal px-6 py-3"
            onClick={closeMenu}
          >
            Témoignages
          </a>
        </li>
        <li>
          <a
            href="#contact"
            className="hover:text-blue-500 px-6 py-3 font-medium md:font-normal text-blue-500 md:text-gray-900"
            onClick={closeMenu}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
