import React from "react";
import client1 from "../assets/client1_128x128.jpeg";
import client2 from "../assets/client2_128x128.jpeg";
import client3 from "../assets/client3_128x128.jpeg";
import client4 from "../assets/client4_128x128.jpeg";
import client5 from "../assets/client5_128x128.jpeg";
import client6 from "../assets/client6_128x128.jpeg";
import { Check } from "lucide-react";

const Header = () => {
  return (
    <header className="py-16 pb-12 md:py-12">
      <div className="flex justify-center">
        <div className="hidden md:block absolute w-48 h-24 scale-[5] bg-gradient-to-tr from-[#527FF6] from-[-20%] via-[#E16DD9] via-40% to-[#FFCC70] to-[130%] rounded-full blur-3xlxl md:blur-2xl -z-20 mt-52 md:mt-96 -top-72 rotate-45 left-0 opacity-100"></div>
      </div>
      <div className="mt-12 md:mt-0 container mx-auto flex flex-col md:flex-row items-center text-center md:mb-12">
        <div className="flex-1 mb-16 md:mb-0 justify-center">
          <div className="flex flex-row justify-center mb-2 md:mb-4">
            <span className="bg-blue-100 text-blue-600 border-blue-600 text-sm md:font-medium me-2 px-2.5 py-0.5 rounded-full">
              Site web dès 49€/mo
            </span>
            <span className="flex flex-row gap-2 bg-emerald-100 text-emerald-600 border-emerald-600 text-sm md:font-medium me-2 px-2.5 py-0.5 rounded-full ">
              Satisfait ou remboursé <Check size={20} />
            </span>
          </div>

          <h1 className="px-2 md:px-0 font-kantumruy text-5xl text-center max-w-2xl md:text-7xl font-semibold text-gray-900 mb-4 mx-auto">
            Votre{" "}
            <span className="bg-gradient-to-tr from-[#527FF6] from-[-20%] via-[#E16DD9] via-40% to-[#FFCC70] to-[130%] bg-clip-text text-transparent">
              site web
            </span>{" "}
            sans prise de tête !
          </h1>

          <p className="px-2 md:px-0 text-center mx-auto max-w-xl text-xl text-gray-600 md:text-gray-500 mb-16">
            Obtenez un site web professionnel et moderne rapidement et sans vous
            ruiner.
          </p>

          <div className="flex w-screen md:w-auto flex-col md:flex-row gap-4 items-center justify-center ">
            <a href="#tarifs">
              <button className="w-[80vw] md:w-auto text-center group inline-flex items-center justify-center hover:shadow-none shadow-lg shadow-blue-500/50 duration-300 font-semibold ease-in-out bg-gradient-to-t from-blue-500 via-blue-500 via-60% to-blue-400 text-white py-3 px-6 rounded-lg text-lg">
                Obtenir mon site web
              </button>
            </a>
            <a href="#a-propos">
              <button
                type="button"
                className="w-[80vw] md:w-auto text-center font-poppins py-3 px-4 h-full inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-transparent text-gray-800 hover:underline"
              >
                En savoir plus
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="md:mt-24  mx-auto text-center flex justify-center relative items-center">
        <img
          src={client1}
          alt=""
          className="border-white border-2 rounded-full h-12 md:h-16 relative -z-10"
        />
        <img
          src={client2}
          alt=""
          className="border-white border-2 rounded-full h-12 md:h-16 relative -ml-4 md:-ml-5 -z-9"
        />
        <img
          src={client3}
          alt=""
          className="border-white border-2 rounded-full h-12 md:h-16 relative -ml-4 md:-ml-5 -z-8"
        />
        <img
          src={client5}
          alt=""
          className="border-white border-2 rounded-full h-12 md:h-16 relative -ml-4 md:-ml-5 -z-7"
        />
        <img
          src={client6}
          alt=""
          className="border-white border-2 rounded-full h-12 md:h-16 relative hidden md:block -ml-4 md:-ml-5 -z-6"
        />
        <img
          src={client4}
          alt=""
          className="border-white border-2 rounded-full h-12 md:h-16 relative hidden md:block -ml-4 md:-ml-5 -z-5"
        />
        <div className="flex flex-col">
          <a
            href="#temoignages"
            className="text-start ml-4 font-poppins text-gray-600 md:text-gray-500 underline"
          >
            +33 clients satisfaits
          </a>{" "}
          <a
            href="#temoignages"
            className="ml-4 font-poppins text-gray-600 md:text-gray-500 underline"
          >
            nous ont fait confiance
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
