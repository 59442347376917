import { ArrowUpNarrowWide, Hammer, Heart, SearchCheck } from "lucide-react";
import React from "react";

const Pourquoi = () => {
  return (
    <section className=" py-20 mx-6 md:mx-0 md:mt-24 mt-8">
      <div className="container mx-auto flex flex-col md:flex-row items-center md:gap-20 ">
        <div className="flex-1 text-end md:text-start">
          <span
            id="a-propos"
            className="font-poppins text-gray-600 mb-4 block uppercase"
          >
            STATS
          </span>
          <h2 className="text-kantumruy font-semibold text-3xl md:text-6xl text-gray-900  mb-6 md:mb-12">
            Pourquoi un site ?
          </h2>
          <p className="font-poppins text-gray-600 md:text-gray-500 text-lg mb-8 md:mb-12 leading-relaxed">
            <strong className="font-normal text-gray-900">
              Un site internet est votre carte de visite virtuelle
            </strong>
            . C'est ce qui ouvre votre entreprise au monde. C'est là où les gens
            font votre connaissance. Les chiffres parlent d'eux mêmes:
          </p>
          <a href="#tarifs" className="hidden md:block">
            <button className="group inline-flex items-center hover:shadow-lg hover:shadow-blue-500/50 duration-300 font-semibold ease-in-out bg-gradient-to-t from-blue-500 via-blue-500 via-60% to-blue-400 text-white py-3 px-6 rounded-lg text-lg">
              Voir les offres
            </button>
          </a>
        </div>

        <div className=" flex-1 ">
          <ul className="flex flex-col gap-4">
            <li className="bg-gray-200 group flex flex-row p-6 gap-4 hover:bg-blue-200 hover:scale-105 duration-200 rounded-3xl font-poppins items-center">
              <div className="rounded-lg bg-gray-600 group-hover:bg-blue-600 p-3 flex items-center justify-center">
                <Hammer className="text-white" />
              </div>
              <p className="cursor-default">
                58% des artisans disposent d'un site internet.
              </p>
            </li>
            <li className="bg-gray-200 group flex flex-row p-6 gap-4 hover:bg-blue-200  hover:scale-105 duration-200 rounded-3xl font-poppins items-center">
              <div className="rounded-lg bg-gray-600 group-hover:bg-blue-600 p-3 flex items-center justify-center">
                <Heart className="text-white" />
              </div>
              <p className="cursor-default">
                51% des français ont utilisé internet pour trouver des
                informations relatives à leur santé.
              </p>
            </li>
            <li className="bg-gray-200 group flex flex-row p-6 gap-4 hover:bg-blue-200  hover:scale-105 duration-200 rounded-3xl font-poppins items-center">
              <div className="rounded-lg bg-gray-600 group-hover:bg-blue-600 p-3 flex items-center justify-center">
                <SearchCheck className="text-white" />
              </div>
              <p className="cursor-default">
                74% des personnes consultent le web lors de la recherche d'un
                prestataire de services à domicile.
              </p>
            </li>
            <li className="bg-gray-200 group flex flex-row p-6 gap-4 hover:bg-blue-200  hover:scale-105 duration-200 rounded-3xl font-poppins items-center">
              <div className="rounded-lg bg-gray-600 group-hover:bg-blue-600 p-3 flex items-center justify-center">
                <ArrowUpNarrowWide className="text-white" />
              </div>
              <p className="cursor-default">
                81% des dirigeants déclarent que le numérique représente un
                bénéfice réel pour leur entreprise.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Pourquoi;
