import Navbar from "../components/Navbar";
import APropos from "../sections/APropos";
import Contact from "../sections/Contact";
import Footer from "../sections/Footer";
import Gallery from "../sections/Gallery";
import Header from "../sections/Header";
import Pricing from "../sections/Pricing";
import Testimonial from "../sections/Temoignages";
import Pourquoi from "../sections/Pourquoi";

import React from "react";
import ActivePeople from "../components/ActivePeople";
import Bento from "../sections/Bento";
import axios from "axios";

const Home = () => {
  // Ajout de useEffect pour vérifier le paramètre ref au chargement
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refCode = urlParams.get("ref");

    const fetchAffiliationUrl = async () => {
      try {
        if (!refCode) return;

        await axios.post("https://api.ethan-dh.fr/api/new-click", {
          code: refCode,
        });
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du lien d'affiliation:",
          error
        );
      }
    };

    fetchAffiliationUrl();
  }, []);

  return (
    <>
      <ActivePeople />
      {/* <Notification /> */}

      <div className="2xl:w-2/3 lg:w-5/6 mx-auto selection:bg-indigo-700 selection:text-white semibold">
        <Navbar />

        <Header />
        {/* <Steps /> */}
        <Gallery />
        <Bento />
        <APropos />
        <Pourquoi />
        {/* <Manifeste /> */}
        {/* <Processus /> */}

        <Pricing />
        <Testimonial />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Home;
