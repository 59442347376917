import { X } from "lucide-react";
import React, { useState, useEffect } from "react";

const getRealisticPeopleCount = () => {
  const hour = new Date().getHours();
  if (hour >= 9 && hour <= 21) {
    // Peak hours (9 AM - 9 PM)
    return Math.floor(Math.random() * (40 - 30 + 1)) + 30; // between 30 and 70 people
  } else {
    // Off-peak hours
    return Math.floor(Math.random() * (11 - 6 + 1)) + 6; // between 6 and 21 people
  }
};

const ActivePeople = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [peopleCount, setPeopleCount] = useState(getRealisticPeopleCount());
  const [isClosing, setIsClosing] = useState(false); // Track swipe-out animation

  const closeNotification = () => {
    setIsClosing(true); // Trigger the swipe-left animation
    setTimeout(() => {
      setIsVisible(false); // Hide after animation
      setIsClosing(false); // Reset animation state
    }, 500); // Match the animation duration
  };

  // Reappear the notification after 1 minute if closed
  useEffect(() => {
    let reappearTimeoutId;
    if (!isVisible) {
      reappearTimeoutId = setTimeout(() => {
        setIsVisible(true);
      }, 60000); // 1 minute
    }
    return () => clearTimeout(reappearTimeoutId); // Clean up on unmount
  }, [isVisible]);

  // Update the people count every 30 seconds
  useEffect(() => {
    const peopleIntervalId = setInterval(() => {
      setPeopleCount(getRealisticPeopleCount());
    }, 30000);

    return () => clearInterval(peopleIntervalId); // Clean up on unmount
  }, []);

  // Only show the notification if it's visible
  if (!isVisible && !isClosing) return null;

  return (
    <div
      className={`hidden md:flex z-50 p-1 bg-white md:break-words items-center text-blue-500 leading-none lg:rounded-full lg:inline-flex fixed bottom-0 left-0 md:mb-4 md:ml-4 transition-all duration-500 ease-in-out ${
        isClosing ? "swipe-out" : ""
      }`}
      role="alert"
    >
      <button
        className="hover:bg-blue-100 text-blue-500 font-bold py-1 px-1 rounded-full mr-2"
        onClick={closeNotification} // Pass function reference here
      >
        <X />
      </button>

      <div className="flex-auto">
        <span className="font-semibold text-center md:text-left flex-auto mr-3">
          {peopleCount} personnes sont sur ce site en même temps que vous.
        </span>
      </div>
    </div>
  );
};

export default ActivePeople;
