import React from "react";
import logo from "../assets/logo.svg";
import { Facebook, Instagram } from "lucide-react";

const Footer = () => {
  return (
    <footer className="border-t-2 mx-2">
      <div className="hidden md:block absolute w-64 h-64 scale-150 bg-gradient-to-tr from-[#527FF6] from-[-20%] via-[#E16DD9] via-40% to-[#FFCC70] to-[130%] rounded-full blur-2xl -translate-y-24 -translate-x-16 -z-20 left-0"></div>

      <div className="mx-auto w-full p-4 py-6 lg:py-8 items-center">
        <div className="md:flex md:justify-between">
          {/* Logo and Title */}
          <div className="mb-6 md:mb-0 flex items-center">
            <img src={logo} className="h-8 mr-3" alt="Logo" />
            <span className="text-xl md:text-2xl font-semibold whitespace-nowrap">
              Ethan DH
            </span>
          </div>

          {/* Navigation */}
          <div className="flex flex-col gap-4 mb-6 md:mb-0">
            <h5 className="font-poppins font-semibold">Navigation</h5>
            <ul className="flex flex-col gap-2 text-gray-600">
              <li className="flex flex-row gap-2">
                <a
                  href="/"
                  className="hover:text-blue-500 flex flex-row items-center"
                >
                  Accueil
                </a>
              </li>
              <li className="flex flex-row gap-2">
                <a
                  href="/portoflio"
                  className="hover:text-blue-500 flex flex-row items-center"
                >
                  Portfolio
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="flex flex-col gap-4 mb-6 md:mb-0">
            <h5 className="font-poppins font-semibold">Nos contact</h5>
            <ul className="flex flex-col gap-2 text-gray-600">
              <li className="flex flex-row gap-2">
                E-mail: ethan.delaine@icloud.com
              </li>
              <li className="flex flex-row gap-2">
                Téléphone: +33 6 31 07 08 81
              </li>
              <li>Adresse: Seine Port, 77240</li>
            </ul>
          </div>

          {/* Legal Information */}
          <div className="flex flex-col gap-4">
            <h5 className="font-poppins font-semibold">Légal</h5>
            <ul className="flex flex-col gap-2 text-gray-600">
              <li className="hover:text-blue-500">
                <a href="https://trapezoidal-artichoke-802.notion.site/CGV-Conditions-G-n-rales-de-Vente-25729e64f19c4427b5baad4845f8887c?pvs=4">
                  CGV
                </a>
              </li>
            </ul>
          </div>

          {/* Réseaux */}
          <div className="flex flex-col gap-4">
            <h5 className="font-poppins font-semibold">Nous suivre</h5>
            <ul className="flex flex-row gap-2 ">
              <li className="hover:text-blue-500">
                <a href="https://instagram.com/edh.dev">
                  <Instagram size={20} />
                </a>
              </li>
              <li className="hover:text-blue-500">
                <a href="https://www.facebook.com/people/EDH-Sites-internet/61565286840838">
                  <Facebook size={20} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
