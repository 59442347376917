import React from "react";
import logos from "../assets/logos.svg";
import draganddrop from "../assets/draganddrop.svg";
import mediakit from "../assets/mediakit.svg";
import brandbook from "../assets/brandbook.svg";
import filetypes from "../assets/filetypes.svg";
import versions from "../assets/versions.svg";

const Bento = () => {
  return (
    <div className="w-full max-w-4xl mx-auto mt-24 md:my-36">
      <div className="text-center">
        <span className="font-poppins text-gray-600 block mb-4 uppercase">
          SERVICES
        </span>
        <h2 className="text-kantumruy font-semibold text-3xl md:text-6xl text-gray-900 ">
          Que proposons-nous ?
        </h2>
      </div>

      <div className="w-11/12 mx-auto mt-12 md:mt-24 grid grid-cols-2 md:grid-cols-6 gap-4 auto-rows-[minmax(100px,_1fr)]">
        {/* First div: 2x2 (spanning 2 columns and 2 rows) */}
        <div className="relative text-gray-900 font-kantumruy font-semibold rounded-2xl text-center flex flex-col justify-end text-2xl bg-center pb-6 col-span-1 row-span-1 md:col-span-2 bg-cover md:row-span-2 aspect-square">
          <div
            style={{ backgroundImage: "url(" + draganddrop + ")" }}
            className="rounded-2xl absolute inset-0 bg-cover bg-center opacity-60 md:opacity-100"
          />
          <span className="relative z-10">Création de site web</span>
        </div>

        {/* Second div: 4x2 (spanning 4 columns and 2 rows) */}
        <div className="relative text-gray-900 font-kantumruy font-semibold rounded-2xl text-center flex flex-col justify-end text-2xl bg-center pb-6 col-span-1 row-span-1 md:col-span-4 bg-cover md:row-span-2">
          <div
            style={{ backgroundImage: "url(" + brandbook + ")" }}
            className="rounded-2xl absolute inset-0 bg-cover bg-center opacity-60 md:opacity-100"
          />
          <span className="relative z-10">Refonte de site web</span>
        </div>

        {/* Third div: 2x2 (below the first, spanning 2 columns and 2 rows) */}
        <div className="relative text-gray-900 font-kantumruy font-semibold rounded-2xl text-center flex flex-col justify-end text-2xl bg-center pb-6 col-span-1 row-span-1 md:col-span-2 bg-cover md:row-span-2 aspect-square">
          <div
            style={{ backgroundImage: "url(" + versions + ")" }}
            className="rounded-2xl absolute inset-0 bg-cover bg-center opacity-60 md:opacity-100"
          />
          <span className="relative z-10">Maintenance de site web</span>
        </div>

        {/* Fourth div: 2x2 */}
        <div className="relative text-gray-900 font-kantumruy font-semibold rounded-2xl text-center flex flex-col justify-end text-2xl bg-center pb-6 col-span-1 row-span-1 md:col-span-2 bg-cover md:row-span-2 aspect-square">
          <div
            style={{ backgroundImage: "url(" + mediakit + ")" }}
            className="rounded-2xl absolute inset-0 bg-cover bg-center opacity-60 md:opacity-100"
          />
          <span className="relative z-10">Hébergement web</span>
        </div>

        {/* Fifth div: 2x1 (spanning 2 columns and 1 row) */}
        <div className="relative text-gray-900 font-kantumruy font-semibold rounded-2xl text-center flex flex-col justify-end text-2xl bg-center pb-6 col-span-2 row-span-1 md:col-span-2 bg-cover md:row-span-1">
          <div
            style={{ backgroundImage: "url(" + logos + ")" }}
            className="rounded-2xl absolute inset-0 bg-cover bg-center opacity-60 md:opacity-100"
          />
          <span className="relative z-10">SEO - Référencement</span>
        </div>

        {/* Sixth div: 2x1 (spanning 2 columns and 1 row, below the fifth) */}
        <div className="relative text-gray-900 font-kantumruy font-semibold rounded-2xl text-center flex flex-col justify-end text-2xl bg-center pb-6 col-span-2 row-span-1 md:col-span-2 bg-cover md:row-span-1">
          <div
            style={{ backgroundImage: "url(" + filetypes + ")" }}
            className="rounded-2xl absolute inset-0 bg-cover bg-center opacity-60 md:opacity-100"
          />
          <span className="relative z-10">Dernières technos</span>
        </div>
      </div>
    </div>
  );
};

export default Bento;
