import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";

export default function App() {
  useEffect(() => {
    fetch("https://api.ethan-dh.fr/refresh-payments");
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        <Route path="/portfolio" element={<Portfolio />} />{" "}
      </Routes>
    </Router>
  );
}
