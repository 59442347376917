import React from "react";
import image from "../assets/agency-ia.png";
import image1 from "../assets/aipng.png";
import image2 from "../assets/animaux.png";
import image3 from "../assets/apex.png";
import image4 from "../assets/btp.png";
import image5 from "../assets/portfolio.png";
import image6 from "../assets/qs.png";
import image7 from "../assets/resto.png";
import image8 from "../assets/sport.png";
import image9 from "../assets/zoo.png";
import image10 from "../assets/compta.png";
import image11 from "../assets/coach.jpeg";
import image12 from "../assets/vip-coaching.jpeg";
import image13 from "../assets/animaux3.png";

import { SquareArrowOutUpRight } from "lucide-react";
const Gallery = () => {
  return (
    <div className="flex flex-col">
      <div className="my-12 mx-auto w-full inline-flex flex-nowrap overflow-hidden md:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)] [mask-image:_linear-gradient(to_right,transparent_0,_black_64px,_black_calc(100%-50px),transparent_100%)]">
        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-2 [&_img]:max-w-none animate-infinite-scroll">
          <li>
            <img
              src={image}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image1}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image2}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image3}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image4}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image5}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image6}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image7}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image8}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image9}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image10}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image11}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image12}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image13}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
        </ul>
        <ul
          className="flex items-center justify-center md:justify-start [&_li]:mx-2 [&_img]:max-w-none animate-infinite-scroll"
          aria-hidden="true"
        >
          <li>
            <img
              src={image}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image1}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image2}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image3}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image4}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image5}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image6}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image7}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image8}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image9}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image10}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image11}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image12}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
          <li>
            <img
              src={image13}
              className="h-56 aspect-[16/9] object-cover rounded-xl"
              alt="Exemple de site"
            />
          </li>
        </ul>
      </div>
      {/* <button className="mx-auto">
        <a
          href="/portfolio"
          target="_blank"
          className="text-neutral-900 flex flex-row gap-4 items-center px-6 py-3 bg-gray-300/80 font-semibold rounded-lg hover:scale-105 transition-all active:scale-100"
          rel="noreferrer"
        >
          Voir notre portfolio
          <SquareArrowOutUpRight />
        </a>
      </button> */}
    </div>
  );
};

export default Gallery;
