import React, { useState } from "react";
import { ChevronLeft, ChevronRight, Star } from "lucide-react";
import { useSwipeable } from "react-swipeable";
import avatar1 from "../assets/user1.png";
import avatar2 from "../assets/user2.png";
import avatar3 from "../assets/user3.png";
import avatar4 from "../assets/user4.png";

const testimonials = [
  {
    image: avatar1,
    text: "Ethan DH est un professionnel exceptionnel ! Il a créé un site qui dépasse toutes mes attentes. Son écoute et son expertise ont permis de réaliser un projet sur mesure qui correspond parfaitement à mes besoins.",
    name: "JEAN N.",
    title: "PLOMBIER",
  },
  {
    image: avatar2,
    text: "Je recommande fortement Ethan DH. Il a su respecter les délais tout en fournissant un travail de qualité remarquable. Grâce à lui, mon site est bien plus performant et esthétique qu'avant.",
    name: "MARTINE D.",
    title: "ARCHITECTE",
  },
  {
    image: avatar3,
    text: "Ethan DH a su répondre à toutes nos demandes en un temps record. La qualité de son travail est incroyable et nous ne pouvions pas espérer mieux pour notre entreprise.",
    name: "PAUL L.",
    title: "ENTREPRENEUR",
  },
  {
    image: avatar4,
    text: "Je suis ravi d'avoir fait appel à Ethan DH pour mon site web. Simple, efficace, et générateur de nouveaux clients, il a vraiment su faire la différence.",
    name: "AMIR B.",
    title: "PLOMBIER",
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
  });

  return (
    <section
      className="text-center py-8 md:py-16 mt-12 md:mt-24"
      id="temoignages"
    >
      <span className="font-poppins text-gray-600 block mb-4 uppercase">
        TÉMOIGNAGES
      </span>
      <div className="flex justify-center mb-8 md:mb-16">
        <h2 className="text-kantumruy w-11/12 md:w-7/12 font-semibold text-3xl md:text-6xl text-gray-900 mb-6 md:mb-12">
          Ce que nos clients disent de nous.
        </h2>
      </div>

      <div
        className="relative flex flex-col items-center justify-center min-h-[400px] md:min-h-[450px] px-4 md:px-0"
        {...handlers}
      >
        {/* Blurred gradient background */}
        <div className="absolute w-48 h-48 md:w-64 md:h-64 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full blur-3xl -inset-y-10 md:-inset-y-14 opacity-35"></div>

        {/* Left Dots */}
        <div className="absolute hidden md:block w-4 md:w-8 h-4 md:h-8 bg-neutral-300 rounded-full left-16 md:left-36 inset-y-64 md:inset-y-96"></div>
        <div className="absolute hidden md:block w-4 md:w-8 h-4 md:h-8 bg-neutral-300 rounded-full left-0 inset-y-20 md:inset-y-24"></div>
        <div className="absolute hidden md:block w-4 md:w-8 h-4 md:h-8 bg-neutral-300 rounded-full left-16 md:left-36 -inset-y-20 md:-inset-y-32"></div>

        {/* Right Dots (Mirrored) */}
        <div className="absolute hidden md:block w-4 md:w-8 h-4 md:h-8 bg-neutral-300 rounded-full right-16 md:right-36 inset-y-64 md:inset-y-96"></div>
        <div className="absolute hidden md:block w-4 md:w-8 h-4 md:h-8 bg-neutral-300 rounded-full right-0 inset-y-20 md:inset-y-24"></div>
        <div className="absolute hidden md:block w-4 md:w-8 h-4 md:h-8 bg-neutral-300 rounded-full right-16 md:right-36 -inset-y-20 md:-inset-y-32"></div>

        {/* Avatar image */}
        <img
          src={testimonials[currentIndex].image}
          alt="Client Avatar"
          className="relative z-10 w-16 md:w-24 h-16 md:h-24 rounded-full mb-6 md:mb-12"
        />
        {/* 5 Star Label */}
        <div className="flex justify-center items-center mb-6">
          <span className="flex flow-row  bg-yellow-100 border-yellow-400  text-xs font-medium px-2.5 py-0.5 rounded-full ">
            <Star size={16} className="text-yellow-400" fill="#fbbf24" />
            <Star size={16} className="text-yellow-400" fill="#fbbf24" />
            <Star size={16} className="text-yellow-400" fill="#fbbf24" />
            <Star size={16} className="text-yellow-400" fill="#fbbf24" />
            <Star size={16} className="text-yellow-400" fill="#fbbf24" />
          </span>
        </div>
        {/* Testimonial text */}
        <p className="font-poppins text-gray-600 text-base md:text-lg mb-6 px-8 md:mb-12 w-full md:w-7/12">
          {testimonials[currentIndex].text}
        </p>

        {/* Client Name and Title */}
        <div className="font-poppins text-gray-900 font-semibold text-base md:text-lg">
          {testimonials[currentIndex].name}
        </div>
        <div className="font-poppins text-gray-500 text-sm">
          {testimonials[currentIndex].title}
        </div>

        {/* Pagination dots */}
        <div className="mt-4 md:mt-8 flex space-x-2">
          {testimonials.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 md:w-3 md:h-3 rounded-full transition-transform duration-300 ease-in-out ${
                index === currentIndex ? "bg-blue-500 scale-110" : "bg-gray-300"
              } cursor-pointer`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>

        {/* Arrows for navigation */}
        <div className="absolute left-2 md:left-5 top-1/2 transform -translate-y-1/2 cursor-pointer z-20">
          <ChevronLeft
            size={24}
            className="text-gray-500 hover:text-gray-700"
            onClick={handlePrevious}
          />
        </div>
        <div className="absolute right-2 md:right-5 top-1/2 transform -translate-y-1/2 cursor-pointer z-20">
          <ChevronRight
            size={24}
            className="text-gray-500 hover:text-gray-700"
            onClick={handleNext}
          />
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
